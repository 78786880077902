ptz-fixed-footer {
  width: 100%;
  .progressBar,
  .progressBarMobile {
    position: absolute;
    width: 0%;
    transition: width 0.2s ease-out;
    -webkit-appearance: none;
    height: 5px;
    top: 0;
    left: 0;
    display: block;
    @media (max-width: $petz-breakpoint-md) {
      -webkit-animation: slide-bottom 0.8s linear both, blur-in 0.1s linear both;
      bottom: 0;
      display: none;
      margin: 0;
    }
  }
  .srs-progress-bar {
    background: $seres-color-primary-brand;
  }
  .ptz-progress-bar {
    background: $petz-color-primary-brand;
  }
  #mobile {
    display: none;
    @media (max-width: $petz-breakpoint-md) {
      position: fixed;
      height: 5px;
      top: unset;
      display: flex;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 15px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    background: rgb(255, 61, 94);
  }
  .widget {
    width: 50px;
    height: 50px;
    position: fixed;
    border-radius: $petz-rounded-max;
    bottom: $seres-spacing-lg;
    z-index: 9;
    right: $seres-spacing-lg;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    @media (min-width: $petz-breakpoint-md) {
      display: none;
    }
    @media (max-width: $petz-breakpoint-sm) {
      bottom: 10px;
    }
  }
  .srs-widget {
    background: $seres-color-primary-brand;
    border: $petz-border-sm solid $seres-color-primary-brand;
  }
  .ptz-widget {
    background: $petz-color-primary-brand;
    border: $petz-border-sm solid $petz-color-primary-brand;
  }
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.8s linear both;
}
@keyframes slide-bottom {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0px);
  }
}

.ptz-fixed-footer {
  &-bar {
    background-color: $seres-color-neutral-white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding: $seres-spacing-sm $seres-spacing-2xl;
    align-items: center;
    justify-content: space-around;
    box-shadow: 0px -4px 24px rgba(0, 0, 0, 0.1);
    display: flex;
    @media (max-width: $petz-breakpoint-md) {
      -webkit-animation: slide-bottom 0.8s linear both, blur-in 0.1s linear both;
      min-height: 250px;
      border-radius: $seres-rounded-2xl $seres-rounded-2xl 0rem 0rem;
    }
    @media (max-width: 320px) {
      height: auto;
    }
  }
  &-wrapper {
    display: flex;
    align-items: center;
    gap: $seres-spacing-md;
    width: 100%;
    justify-content: space-between;
    @media (max-width: $petz-breakpoint-md) {
      display: grid;
      gap: $seres-spacing-sm;
      justify-content: center;
    }
    hr {
      width: 60px;
      height: 4px;
      background: $petz-color-neutral-light;
      border-radius: $petz-rounded-max;
      margin: 0 auto;
      border: none;
      display: none;
      @media (max-width: $petz-breakpoint-md) {
        display: flex;
      }
    }

    &-skeleton {
      margin-right: $petz-spacing-sm;
    }
  }
  &-close-icon {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    p {
      font-weight: $seres-font-weight-bold;
      font-size: $seres-font-size-sm;
      line-height: $seres-font-lineheight-sm;
      color: $seres-color-neutral-darker-accent;
      margin: 0;
    }
    ptz-icon {
      cursor: pointer;
    }
    display: none;
    @media (max-width: $petz-breakpoint-md) {
      margin-top: $seres-spacing-2xs;
      display: flex;
      align-items: flex-start;
    }
  }
  &-divider {
    width: 100% !important;
    height: 1px !important;
  }
  &-text {
    display: grid;
    font-style: normal;
    color: $seres-color-neutral-black;
    line-height: $seres-font-lineheight-sm;
    width: 800px;
    gap: $petz-spacing-3xs;
    @media (max-width: 1270px) {
      width: 500px;
    }
    @media (max-width: $petz-breakpoint-md) {
      width: 100%;
      margin-top: $seres-spacing-2xs;
      margin-bottom: $seres-spacing-3xs;
    }
    p {
      font-weight: $seres-font-weight-bold;
      font-size: $seres-font-size-sm;
      margin: 0;
      @media (max-width: $petz-breakpoint-md) {
        display: none;
      }
    }
    span {
      font-weight: $seres-font-weight-regular;
      font-size: $seres-font-size-2xs;
      color: $seres-color-neutral-dark;
    }
    @media (max-width: 400px) {
      width: 100%;
    }
  }
  &-cta-wrapper {
    display: flex;
    justify-content: center;

    #light-button {
      margin-right: $seres-spacing-sm;
    }

    @media (max-width: 400px) {
      width: 100%;
      margin: 0 auto;
      input {
        font-size: $seres-font-size-3xs;
      }
    }
    @media (max-width: $petz-breakpoint-md) {
      margin-top: $seres-spacing-md;

      ptz-button,
      a {
        width: 100%;
        button {
          width: 100%;
        }
      }
    }
    @media (max-width: $petz-breakpoint-sm) {
      span {
        font-size: $seres-font-size-2xs;
      }
    }
    @media (max-width: 320px) {
      gap: $seres-spacing-2xs;
    }
    #light-button {
      button {
        background-color: $seres-color-neutral-white;
        color: $seres-color-neutral-black;
        border: $petz-border-md solid $seres-color-neutral-black;
        &:hover {
          color: $seres-color-neutral-white;
          background-color: $seres-color-neutral-black;
        }
      }
    }
    ptz-button {
      button {
        position: relative;
        color: $seres-color-neutral-white;
        border: $petz-border-none;
        border-radius: $seres-rounded-xs;
        cursor: pointer;
        z-index: 1;
        font-size: $seres-font-size-xs;
        width: 100%;
        p {
          display: flex;
          justify-content: center;
          gap: $seres-spacing-xs;
          align-items: center;
          font-weight: $seres-font-weight-bold;
          font-size: $seres-font-size-2xs;
        }
        @media (max-width: $petz-breakpoint-md) {
          padding: $seres-spacing-2xs;
        }
        @media (max-width: $petz-breakpoint-sm) {
          font-size: $seres-font-size-2xs;
        }
      }
    }
    .srs-cta-btn {
      button {
        background-color: $seres-color-primary-brand;
        &:hover {
          background-color: $seres-color-primary-light;
        }
      }
    }
    .ptz-cta-btn {
      button {
        background-color: $petz-color-primary-brand;
      }
    }
  }
  &-contact-wrapper {
    display: flex;
    justify-content: center;
  }
  &-srs-contact {
    background: $petz-color-secondary-light;
    border-radius: $seres-rounded-md;
    display: none;
    flex-direction: row;
    align-items: center;
    padding: $seres-spacing-2xs $seres-spacing-sm;
    gap: $seres-spacing-xs;
    width: 271px;
    max-width: 271px;
    align-items: center;
    justify-content: space-evenly;
    p:nth-child(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: $seres-font-weight-bold;
      max-width: 95px;
    }
    p {
      font-size: $seres-font-size-3xs;
      text-align: center;
      display: block;
      min-width: 100px;
      margin: 0;
    }
    span {
      font-weight: $seres-font-weight-bold;
      font-size: $seres-font-size-2xs;
      display: block;
    }
    a {
      display: flex;
      align-self: center;
    }

    @media (max-width: $petz-breakpoint-md) {
      display: flex;
      margin: 0 $seres-spacing-md;
    }
    @media (max-width: 340px) {
      margin: 0 auto !important;
    }
    @media (max-width: $petz-breakpoint-sm) {
      width: 100% !important;
    }
    @media (max-width: 400px) {
      width: 100%;
      gap: $seres-spacing-2xl;
    }
  }
}

import { Component, Event, Host, Prop, State, Watch, h } from '@stencil/core';
import { PTZBrandConfig } from '../ptz-brand/types/ptz-brand.enums';
import { PTZBrandTypes } from '../ptz-brand/types/ptz-brand.types';
import { PTZIconTypes } from '../ptz-icon/types/ptz-icon.types';

@Component({
  tag: 'ptz-fixed-footer',
  styleUrl: 'ptz-fixed-footer.scss',
  shadow: false,
})
export class PTZFixedFooter {
  /** Título da barra desktop */
  @Prop() mainTitle: string;

  /** Título da barra mobile */
  @Prop() mainTitleMobile?: string;

  /** Subtítulo da barra desktop */
  @Prop() caption: string;

  /** Nome do ícone no Widget mobile */
  @Prop() widgetIconName?: PTZIconTypes.Name;

  /** Variante do ícone no Widget mobile */
  @Prop() widgetIconVariant?: PTZIconTypes.Variant;

  /** Label do botão light */
  @Prop() lightButtonLabel?: string;

  /** Atributo do ícone do primary button */
  @Prop() showIconLightButton?: boolean;

  /** Nome do ícone do light button */
  @Prop() lightButtonIconName?: PTZIconTypes.Name;

  /** Atributo da label do botão primary */
  @Prop() primaryButtonLabel: string;

  /** Atributo do ícone do primary button */
  @Prop() showIconPrimaryButton?: boolean;

  /** Estado de renderização */
  @Prop() skeleton: boolean = false;

  /** Atributo para controlar a barra mobile/desktop  */
  @State() active: boolean = true;

  /** Atributo para pegar o tamanho da tela  */
  @State() width: number = window.innerWidth;

  /** Atributo para controlar se é mobile ou não  */
  @State() isWidthMobile: boolean = this.width <= 768;

  /** Define se vai utilizar estilos da Petz ou de outra marca */
  @Prop() brand: PTZBrandTypes.Name = PTZBrandConfig.Name.Petz;

  /** Atributo para controlar o click do primeiro botão  */
  @Event() clickLightButton: () => void;

  /** Atributo para controlar o click do segundo botão  */
  @Event() clickPrimaryButton: () => void;

  /** Atributo para controlar o click do segundo botão  */
  @Event() clickHandleActive: () => void;

  private resizeListener: () => void;

  componentDidLoad() {
    this.handleScreenWidth();
    this.handleProgressBar();
    this.resizeListener = this.handleResize.bind(this);
    window.addEventListener('resize', this.resizeListener);
  }

  disconnectedCallback() {
    window.removeEventListener('resize', this.resizeListener);
  }

  private handleResize() {
    this.width = window.innerWidth;
  }

  private handleProgressBar() {
    const progressBar = document.querySelector<HTMLElement>('.progressBar');
    const progressBarMobile = document.querySelector<HTMLElement>('.progressBarMobile');
    const section = document.querySelector('body');

    const setWidth = (element: HTMLElement, section: HTMLElement) => {
      const scrollDistance = -section.getBoundingClientRect().top;
      const progressPercentage = (scrollDistance / (section.getBoundingClientRect().height - document.documentElement.clientHeight)) * 100;

      const val = Math.floor(progressPercentage);
      element.style.width = val + '%';

      if (val < 0) {
        element.style.width = '0%';
      }
    };
    if (progressBarMobile && section) {
      const scrollProgressBarMobile = () => {
        setWidth(progressBarMobile, section);
      };
      window.addEventListener('scroll', scrollProgressBarMobile);
    }
    if (progressBar && section) {
      const scrollProgressBar = () => {
        setWidth(progressBar, section);
      };
      window.addEventListener('scroll', scrollProgressBar);
    }
  }

  @Watch('width')
  handleScreenWidth() {
    const widget = document.getElementById('widget');
    this.isWidthMobile = this.width <= 768;
    if (this.isWidthMobile) {
      this.active = false;
      widget.style.display = 'flex';
    } else {
      this.active = true;
      widget.style.display = 'none';
    }
  }

  @Watch('active')
  handleBar() {
    const bar = document.getElementById('bar');
    const progressBarMobile = document.getElementById('mobile');
    if (this.active) {
      bar.style.display = 'flex';
      progressBarMobile.style.display = 'none';
    } else {
      bar.style.display = 'none';
      progressBarMobile.style.display = 'flex';
    }
  }

  render() {
    const classButton = `${this.brand}-cta-btn`;
    const classProgressBar = `${this.brand}-progress-bar`;
    const classWidget = `${this.brand}-widget`;

    return (
      <Host class={`${this.active}`}>
        <div class={`widget ${classWidget}`} id="widget" onClick={() => (this.active = !this.active)}>
          <ptz-icon name={this.widgetIconName} variant={this.widgetIconVariant} size="lg" color="neutral-white" brand={this.brand} />
        </div>
        <div class={`progressBarMobile ${classProgressBar}`} id="mobile"></div>
        <div class={'ptz-fixed-footer-bar'} id="bar">
          <div class={`progressBar ${classProgressBar}`}></div>
          <div class={'ptz-fixed-footer-wrapper'}>
            <hr />
            <div class={'ptz-fixed-footer-close-icon'}>
              <p>{this.mainTitleMobile ? this.mainTitleMobile : this.mainTitle}</p>
              <ptz-icon name="times-circle" variant="line" size="lg" color="neutral-darker-accent" brand={this.brand} onClick={() => (this.active = false)} />
            </div>
            <hr class={'ptz-fixed-footer-divider'} />
            <div class={'ptz-fixed-footer-text'}>
              <p>{this.skeleton ? <ptz-skeleton width={202} height={20}></ptz-skeleton> : this.mainTitle}</p>
              <span>{this.skeleton ? <ptz-skeleton width={343} height={20}></ptz-skeleton> : this.caption}</span>
            </div>
            <div class={'ptz-fixed-footer-contact-wrapper'}>
              {this.brand === 'ptz' ? (
                this.skeleton ? (
                  <ptz-skeleton width={180} height={20}></ptz-skeleton>
                ) : (
                  <slot name="ptz-contact" />
                )
              ) : (
                <div class={'ptz-fixed-footer-srs-contact'}>
                  {this.skeleton ? <ptz-skeleton width={100} height={26}></ptz-skeleton> : <p>Fale com a gente</p>}

                  {this.skeleton ? (
                    <ptz-skeleton width={100} height={26}></ptz-skeleton>
                  ) : (
                    <p>
                      Alô Petz <span>(11) 3434-6980</span>
                    </p>
                  )}
                </div>
              )}
            </div>
            <div class={'ptz-fixed-footer-cta-wrapper'}>
              {this.brand === 'srs' &&
                (this.skeleton ? (
                  <ptz-skeleton class="ptz-fixed-footer-wrapper-skeleton" width={150} height={48}></ptz-skeleton>
                ) : (
                  <ptz-button
                    label={this.lightButtonLabel}
                    brand={this.brand}
                    size="md"
                    appearance="dark"
                    iconName={this.showIconLightButton ? this.lightButtonIconName : undefined}
                    iconVariant={this.showIconLightButton ? 'line' : undefined}
                    iconAlign={this.showIconLightButton ? 'right' : undefined}
                    id="light-button"
                    onClick={this.clickLightButton}
                  />
                ))}
              {this.skeleton ? (
                <ptz-skeleton width={150} height={48}></ptz-skeleton>
              ) : (
                <ptz-button
                  label={this.primaryButtonLabel}
                  brand={this.brand}
                  size="md"
                  appearance="light"
                  iconName={this.showIconPrimaryButton ? 'arrow-right' : undefined}
                  iconVariant={this.showIconPrimaryButton ? 'line' : undefined}
                  iconAlign={this.showIconPrimaryButton ? 'right' : undefined}
                  onClick={this.clickPrimaryButton}
                  class={classButton}
                />
              )}
            </div>
          </div>
        </div>
      </Host>
    );
  }
}
